import React, { useState, useEffect } from "react";
import Layout from "../../components/layoutnew";
import SEO from "../../components/seo";
import awsPartner from "../../images/aws_partner.png";
import { graphql } from "gatsby";

const AWSPublic = ({ data }) => {
  const getData = data.allStrapiAwsPublicSupport.edges[0].node;
  return (
    <Layout slug={getData.slug} locale={getData.locale}>
      <SEO
        title={getData.metaTitle}
        slug={getData.slug}
        locale={getData.locale}
        noindexForce={true}
        description={getData.metaDes}
      />
      <div>
        <div className="platformHeroSection">
          <div className="containerwrap">
            <div className="platformHeroSectionTitle ">
              <div>{getData.AwsStatement.heading}</div>
            </div>
            <div className="platformHeroSectionHeading">
              {getData.AwsStatement.title}
            </div>
            <div
              className="platformHeroSectionDescription"
              dangerouslySetInnerHTML={{ __html: getData.AwsStatement.des }}
            />

            <div className="d-flex justify-content-center">
              <img src={awsPartner} height={250} alt="AWS partner" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AWSPublic;

export const query = graphql`
  query AWSPublicSupport {
    allStrapiAwsPublicSupport {
      edges {
        node {
          metaDes
          metaTitle
          slug
          locale
          AwsStatement {
            heading
            title
            des
          }
        }
      }
    }
  }
`;
